// app/javascript/controllers/checkbox_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox"]

  update(event) {
    var total_shipping = 0.0;
    var currency = "";
    var vendor_sum = {};
    $(".shipping-method-time-table-radio:checked").each(function(){
      total_shipping = total_shipping + parseFloat($("#total_"+$(this).attr('id')).val());
      currency = $("#"+$(this).attr('id')+"-currency").val();
      var vendor_id = $(this).attr('id').split("_")[1];
      if(vendor_sum[vendor_id] == null){
        vendor_sum[vendor_id] = 0;
      }
      vendor_sum[vendor_id] = parseFloat(total_shipping);
      var total_vendor_sum = parseFloat(vendor_sum[vendor_id])+parseFloat($(".total-vendor_bottom_"+vendor_id).val())
      $(".total_price_vendor_bottom_"+vendor_id).html(total_vendor_sum.toFixed(2)+" "+currency)
    });

    

    $('.product-shipping-total').html(total_shipping.toFixed(2)+" "+currency);
    var sum_total = $(".sum-total").val();
    $(".total_price_right_bottom").text((parseFloat(sum_total)+parseFloat(total_shipping)).toFixed(2)+" "+currency);
  }
}
