import { Controller } from "stimulus"
import { initializeMobileInputs } from '../packs/mobile_input_handler.js';
import '../packs/product_info.js'
// Connects to data-controller="modal"
export default class extends Controller {
        connect() {
        this.modal = new bootstrap.Modal(this.element, {
            keyboard: false,
            backdrop: 'static'
        })

        this.modal.show();
        initializeMobileInputs();
        if (typeof  reset_map === "function") {
            reset_map();
        }

        if(typeof init_shop_map === "function"){
            init_shop_map();
        }

       
        if ($('.product-qty-form').length !== 0) {
            init_quantity();
        }

        if ($('.product-variations-modal').length !== 0) {
            init_variations();
        }


        $('#vendor_addresses_book_collection_days').chosen({
            allow_single_deselect: true,
            width: '100%'
        });

        $('.district-for-districts').chosen({
            allow_single_deselect: true,
            width: '100%'
        });

        $('#product_restriction_shop_ids').chosen({
            allow_single_deselect: true,
            width: '100%'
        });

        $('#product_restriction_product_id').chosen({
            allow_single_deselect: true,
            width: '100%'
        });

        $('#international_shipping_product_id').chosen({
            allow_single_deselect: true,
            width: '100%'
        });

        $('#international_shipping_vendor_category_product_category').chosen({
            allow_single_deselect: true,
            width: '100%'
        });

        if ($('#international_shipping_country_code').length !== 0){
            $('#international_shipping_country_code').on('change', function(evt, params) {
                fetch('/en/home/vendors/1/vendor_international_shippings/get_currency_code?country_code=' + $('#international_shipping_country_code').val(), {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => response.text())
                .then((result) => {
                    $('#international-shipping-currency-code-label').text(JSON.parse(result).currency_code)
                });
            });
        }

        if ($('#vendor_shipping_method_paid_by').length !== 0){
            $('#vendor_shipping_method_paid_by').on('change', function(evt, params) {
            if ($('#vendor_shipping_method_paid_by').val() == 'shop') {
                $('#vendor_shipping_method_shipping_preference').closest('.col-md-12').hide()
            } else {
                $('#vendor_shipping_method_shipping_preference').closest('.col-md-12').show()
            }
            });
        }      

        if($('#order_item_delivery_order_vendor_addresses_book_id').length > 0) {
            $('#order_item_delivery_order_vendor_addresses_book_id').on('change', function(evt, params) {
                let href = $('#order_item_delivery_order_from_href').val() + '&vendor_addresses_book_id=' + $('#order_item_delivery_order_vendor_addresses_book_id').val()               
                $('#close_product_process_modal').click()
                fetch(href, {
                    headers: {
                        Accept: "text/vnd.turbo-stream.html"
                    }
                })
                    .then(r => r.text())
                    .then(html => Turbo.renderStreamMessage(html))
                });
        }

        $('#vendor_address_book_countries_city_city_code').chosen({
            allow_single_deselect: true,
            width: '100%'
        });

        $('#shop_pricing_category_pricing_category_shops').chosen({
            allow_single_deselect: true,
            width: '100%'
        });

        $('#shop_pricing_category_options').chosen({
            allow_single_deselect: true,
            width: '100%'
        });

                
        $('#vendor_pricing_category_excluded_shops').each(function() {
            $(this).chosen({
                allow_single_deselect: true,
                width: '100%'
            });
        });

        $('.excluded_shops_field').chosen({
            allow_single_deselect: true,
            width: '100%'
        });

        $('#delivery_agency_vendors_options').chosen({
            allow_single_deselect: true,
            width: '100%'
        });
        $('#invitation-shop-categories').chosen({
            allow_single_deselect: true,
            width: '100%'
        });

        $('#payment_method_credit_limit_per_shop_shop_id').chosen({
            allow_single_deselect: true,
            width: '100%'
        });

        if ($('#vatrenas_currency_currency_code').length !== 0){
            $('#vatrenas_currency_currency_code').chosen({
              allow_single_deselect: true,
              width: '100%'
            });
          }
                
        if($('input:radio[class="shipping-method-time-table-radio custom-control-input"]').length > 0){
            $('input:radio[class="shipping-method-time-table-radio custom-control-input"]').change(
                function(){
                    if (this.checked) {
                        var vendor_id = $(this).attr('id').split("-")[0]
                        $('.info-shipping-'+vendor_id).hide(400);
                        $("#info-shipping-"+$(this).attr('id')).slideToggle(700);
                    }
            });
        }

        if ($('.location_update').length !== 0) {
            for(var i=0;i<$('.location_update').length;i++){
                start_location_update($($('.location_update')[i]).val());
            }
        }

        if ($('.trial-quantity').length !== 0) {
            $(".trial-quantity-plus").click(function () {
                if(parseInt($('.trial-quantity').val()) < parseInt($('.trial-quantity').attr("max"))) {
                    $('.trial-quantity').val(parseInt($('.trial-quantity').val()) + 1);
                    $('#trial_product_quantity').val($('.trial-quantity').val());
                } else {
                    $('.trial-quantity, #trial_product_quantity, #product_quantity, .quantity').val(parseInt($('.trial-quantity').attr("max")));
                }
            });


            $(".trial-quantity-minus").click(function () {
                if(parseInt($('.trial-quantity').val()) >1) {
                    $('.trial-quantity').val(parseInt($('.trial-quantity').val()) - 1);
                    $('#trial_product_quantity').val($('.trial-quantity').val());
                }
            });

        }
    }


    hideModal() {
        this.element.parentElement.removeAttribute("src");
        this.element.remove();
        stop_map_timer();
        reset_map();
    }

    disconnect() {
        this.modal.hide()
    }
}