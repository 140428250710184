import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["field","map","latitude","longitude"]



  connect() {
    if (typeof google != "undefined"){
      this.renderMap();
    }
  }

  renderMap(){
    this.map()
    this.marker()
    this.autocomplete()
    this.infowindow()
    this.geocoder()
    this.createButton()
  }

  createButton(){

  }

  handleLocationError(browserHasGeolocation, infoWindow, pos) {
  infoWindow.setPosition(pos);
  infoWindow.setContent(
    browserHasGeolocation
      ? "Error: The Geolocation service failed."
      : "Error: Your browser doesn't support geolocation."
  );
  infoWindow.open(this.map());
}

  geocoder(){

    if(this._geocoder == undefined){
      this._geocoder = new google.maps.Geocoder();
    }

    return this._geocoder

  }


  map(){
    if(this._map == undefined){
      this._map = new google.maps.Map(this.mapTarget,{
        center: new google.maps.LatLng(
          this.latitudeTarget.value,
          this.longitudeTarget.value
        ),
        zoom: 15,
        fullscreenControl : false,
        streetViewControl : false,
        mapTypeControl: false
      })
    }
    return this._map
  }

  getCountryCity(results){
    var country = null, countryCode = null, city = null, street_address = null, postal_code = "", state = null;
    for (var i = 0; i < results.length; i++) {
      var result = results[i];
      if(result.types[0] == "route"){
        street_address = result.long_name
      }else if(result.types[0] == "locality"){
        city = result.long_name
      }else if(result.types[0] == "country"){
        countryCode = result.short_name
      }else if(result.types[0] == "postal_code"){
        postal_code = result.short_name
      }else if(result.types[0] == "administrative_area_level_1"){
        state = result.short_name
      }
    }

    if($('#shop_addresses_book_country_code').length >0) {
      $('#shop_addresses_book_country_code').val(countryCode);
      let country = document.getElementById("shop_addresses_book_country_code");
      const event = new Event('change')
      country.dispatchEvent(event)
    }

    if($('#vendor_addresses_book_country_code').length >0) {
      $('#vendor_addresses_book_country_code').val(countryCode);
      let country = document.getElementById("vendor_addresses_book_country_code");
      const event = new Event('change')
      country.dispatchEvent(event)
    }

    if($('#shop_shop_country_code').length >0) {
      $('#shop_shop_country_code').val(countryCode);
      let country = document.getElementById("shop_shop_country_code");
      const event = new Event('change')
      country.dispatchEvent(event)
    }

    if($('#vendor_vendor_address_longitude').length >0){
      $('#vendor_vendor_country').val(countryCode);
      $('#vendor_vendor_city').val(city);
      $('#vendor_vendor_street_name').val(street_address);
      $('#vendor_vendor_postcode').val(postal_code);
      $('#vendor_vendor_state').val(state);
    }

    if($('#shop_shop_address_longitude').length >0){
      $('#shop_shop_country_code').val(countryCode);
      $('#shop_shop_city_name').val(city);
      $('#shop_shop_street_name').val(street_address);
      $('#shop_shop_postcode').val(postal_code);
      $('#shop_shop_state').val(state);
    }
  }

  infowindow(){
      let infoWindow = new google.maps.InfoWindow();

      infoWindow.open(this.map());

      const locationButton = document.createElement("button");
      locationButton.textContent = gon["get"];
      locationButton.classList.add("custom-map-control-button");
      this.map().controls[google.maps.ControlPosition.TOP_CENTER].push(locationButton);
    locationButton.addEventListener("click", (event) => {
      event.preventDefault();
      locationButton.innerHTML = '<div class="spinner-border text-primary" role="status"><span class="visually-hidden"></span></div>';
      // Try HTML5 geolocation.
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            infoWindow.setPosition(pos);
            infoWindow.setContent("Your Location");
            infoWindow.open(this.map());
            this.map().setCenter(pos);

            if($('#vendor_vendor_address_longitude').length >0){
              $('#vendor_vendor_address_longitude').val(pos.lng);
              $('#vendor_vendor_address_latitude').val(pos.lat);
            }

            if($('#shop_shop_address_longitude').length >0){
              $('#shop_shop_address_longitude').val(pos.lng);
              $('#shop_shop_address_latitude').val(pos.lat);
            }

            if($('#vendor_addresses_book_latitude').length >0){
              $('#vendor_addresses_book_longitude').val(pos.lng);
              $('#vendor_addresses_book_latitude').val(pos.lat);
            }

            if($('#vendor_addresses_book_longitude').length >0){
              $('#vendor_addresses_book_longitude').val(pos.lng);
              $('#vendor_addresses_book_latitude').val(pos.lat);
            }

            var latlng = new google.maps.LatLng(pos.lat, pos.lng);


            this.geocoder()
                .geocode({'latLng': latlng})
                .then((response) => {
                  this.getCountryCity(response.results[0].address_components)
                });

            locationButton.innerHTML = "Get Current Location";

            var nextEvent = new Event("clear_form_validations");
            document.dispatchEvent(nextEvent);

          },
          () => {
            this.handleLocationError(true, infoWindow, this.map().getCenter());
            locationButton.innerHTML = "Get Current Location";
          }
        );
      } else {
        // Browser doesn't support Geolocation
        this.handleLocationError(false, infoWindow, this.map().getCenter());
        locationButton.innerHTML = "Get Current Location";
      }
    });

      this.map().addListener("click", (mapsMouseEvent) => {
        infoWindow.close();
        infoWindow = new google.maps.InfoWindow({
          position: mapsMouseEvent.latLng,
        });
        if ( $('#vendor_vendor_name').val() == ""){
          infoWindow.setContent(
              $('#vendor_vendor_name').val()
          );
        }else{
          infoWindow.setContent(
              "Your Location"
          );
        }


        var pos = mapsMouseEvent.latLng;


        if($('#vendor_vendor_address_longitude').length >0){
          $('#vendor_vendor_address_longitude').val(pos.lng);
          $('#vendor_vendor_address_latitude').val(pos.lat);
        }

        if($('#shop_shop_address_longitude').length >0){
          $('#shop_shop_address_longitude').val(pos.lng);
          $('#shop_shop_address_latitude').val(pos.lat);
        }

        this.geocoder()
            .geocode({'latLng': mapsMouseEvent.latLng})
            .then((response) => {
              this.getCountryCity(response.results[0].address_components)
            });

        var nextEvent = new Event("clear_form_validations");
        document.dispatchEvent(nextEvent);

        infoWindow.open(this.map());
      });
  }

  marker(){
    if (this._marker == undefined) {
      this._marker = new google.maps.Marker({
        map: this.map(),
        anchorPoint: new google.maps.Point(0,0)
      })
      let mapLocation = {
        lat: parseFloat(this.latitudeTarget.value),
        lng: parseFloat(this.longitudeTarget.value)
      }
      this._marker.setPosition(mapLocation)
      this._marker.setVisible(true)
    }
    return this._marker
  }

  autocomplete(){
    if(this._autocomplete == undefined){
      
        this._autocomplete = new google.maps.places.Autocomplete(this.fieldTarget)
        this._autocomplete.bindTo('bounds', this.map())
        this._autocomplete.setFields(['address_components','geometry','icon','name'])
        this._autocomplete.addListener('place_changed',this.locationChanged.bind(this))

    }
    return this._autocomplete
  }

  locationChanged(){
    let place = this.autocomplete().getPlace()
    if (!place.geometry) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      window.alert("No details available for input: '" + place.name + "'");
      return;
    }

    this.map().fitBounds(place.geometry.viewport)
    this.map().setCenter(place.geometry.location)
    this.marker().setPosition(place.geometry.location)
    this.marker().setVisible(true)

    this.latitudeTarget.value = place.geometry.location.lat()
    this.longitudeTarget.value = place.geometry.location.lng()

    this.geocoder()
     .geocode({ location:  place.geometry.location })
     .then((response) => {
       this.getCountryCity(response.results[0].address_components)
     });

    var nextEvent = new Event("clear_form_validations");
    document.dispatchEvent(nextEvent);
  }

  preventSubmit(e) {
    if (e.key == "Enter") { e.preventDefault() }
  }

}
