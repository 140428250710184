import { Controller } from "stimulus"
import Dropzone from "dropzone"
import 'dropzone/dist/dropzone.css'
import 'dropzone/dist/basic.css'
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {

  static targets = ["input","imageurl","imagedeleteurl"]

  connect() {
    Dropzone.autoDiscover = false
    this.inputTarget.disable = true
    this.inputTarget.style.display = "none"
    var image_get_url = this.imageurlTarget.value
    var image_delete_url = this.imagedeleteurlTarget.value
    var thisDropzone = null;
    const dropzone = new Dropzone(this.element, {
      url: '/',
      maxFiles: '10',
      maxFilesize: '10',
      acceptedFiles: 'image/*',
      addRemoveLinks: true,
      parallelUploads: 1,
      autoQueue: false,
      withCredentials: true,
      headers: {
        "Cache-Control": "",
      },
      previewTemplate: document.getElementById('template-preview').innerHTML,
      clickable: true,
      removedfile: function(file) {
        var data = {image_id: file.id}
        $.ajax({
          type: "DELETE",
          url: image_delete_url,
          data: data,
          success: (data) => {
            file.previewElement.parentNode.removeChild(file.previewElement);
          }
        })

      },
      init: function() {
          thisDropzone = this;
          $.ajax({
            type: "GET",
            url: image_get_url,
            success: (data) => {
              $.each(data.images, function(key,value){
                var mockFile = { name: value.filename, size: value.byte_size, id:value.id };
                thisDropzone.options.addedfile.call(thisDropzone, mockFile);
                thisDropzone.options.thumbnail.call(thisDropzone, mockFile,value.service_url );
              });
            }
          })
      }
    })

    dropzone.on("addedfile", file => {
      setTimeout(() => {
        if (file.accepted) {
          var csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
          var urlWithToken = this.url + '?authenticity_token=' + encodeURIComponent(csrfToken);
          const upload = new DirectUpload(file, urlWithToken)
          upload.create((error, blob) => {
            console.log(blob);
            this.hiddenInput = document.createElement("input")
            this.hiddenInput.type = "hidden"
            this.hiddenInput.name = this.inputTarget.name
            this.hiddenInput.value = blob.signed_id
            this.hiddenInput.setAttribute("org-name",file.name)
            this.inputTarget.parentNode.insertBefore(this.hiddenInput, this.inputTarget.nextSibling)
            dropzone.emit("success", file)
            dropzone.emit("complete", file)
          })
        }
      }, 500)
    })
  }

  get image_url(){
    return this.inputTarget.getAttribute('data-direct-image-url')
  }

  get url() {
    return this.inputTarget.getAttribute('data-direct-upload-url')
  }

}
