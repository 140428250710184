import { Controller } from "stimulus"

// Connects to data-controller="chat-message"
export default class extends Controller {
    connect() {
        if ($('.chat-logs').length !== 0) {
            $(".chat-logs").stop().animate({ scrollTop: $(".chat-logs")[0].scrollHeight}, 1000);
        }
        if ($('.chat-box-body').length !== 0) {
            $(".chat-box-body").stop().animate({scrollTop: $(".chat-box-body")[0].scrollHeight}, 1000);
        }

        if ($('#chat_boot_room_message').length !==0){
            $('#chat_boot_room_message').focus();
        }

    }
}