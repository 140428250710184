import { Controller } from "stimulus"

import * as bootstrap from "bootstrap"

// Connects to data-controller="timeout-check"
export default class extends Controller {
  static values = { lastRequestAt: Number }

  deviseTimeout = 3600
  isReloaded = false
  isExpired = false

  connect() {
    this.modal = new bootstrap.Modal(this.element.querySelector(".modal-extending"),{
        keyboard: false,
        backdrop: 'static'})
    this.modal_expired = new bootstrap.Modal(this.element.querySelector(".modal-expired"),{
        keyboard: false,
        backdrop: 'static'})
    this.start()
  }
  disconnect() {
    clearInterval(this.timer)
  }

  start() {
    this.timer = setInterval(this.check.bind(this), 1000)
  }

  check() {
    this.currentTime = new Date().valueOf() / 1000
    const timeLeft = this.deviseTimeout - (this.currentTime - this.lastRequestAtValue)
    if (this.lastRequestAtValue == 0) {
      return
    } else if (timeLeft <= 0 ) {
        if(!this.isReloaded){
            this.modal.hide()
            this.modal_expired.show()
            this.isReloaded = true
        }
    } else if ( timeLeft <= 60 ) {
      if(!this.isExpired){
        this.isExpired = true
        this.modal.show()
      }
    }
  }

  redirectToLogin(){
    this.modal_expired.hide()
    window.location.reload()
  }

  extendSession() {
    this.isExpired = false
    this.modal.hide()
    this.currentTime = new Date().valueOf() / 1000
    Turbo.visit("/timeout_checks", { frame: "timeout_checks" })
  }

}