// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "jquery"
import { initializeMobileInputs } from './mobile_input_handler.js';
import './bootstrap_custom.js'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import '@client-side-validations/simple-form/src/index.bootstrap4'

import 'flatpickr/dist/flatpickr'

import "chartkick"

import "chosen-js"

import PhotoSwipe from "photoswipe"

import SmartWizard from "./smart_wizard.js"

import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default.js"
import "@rails/actiontext"
import Swiper, { Navigation, Pagination, Autoplay, Controller, Thumbs } from 'swiper'

import * as bootstrap from "bootstrap"

import './block-ui.js'

import ahoy from "ahoy.js"

require("controllers")

require("@rails/actiontext")


//= require social-share-button

//= require simple_calendar
//= require pagy

//= require jquery.blockUI

window.PhotoSwipe = PhotoSwipe

window.SmartWizard = SmartWizard


window.PhotoSwipeUI_Default = PhotoSwipeUI_Default

window.Swal = Swal

window.bootstrap = bootstrap

Swiper.use([ Navigation, Pagination, Autoplay, Controller, Thumbs ])

window.Swiper = Swiper


global.dataTables = [];

window.update_user_web_token = function(token) {
  $.ajax({
    url: '/update_user_web_token',
    type: 'POST',
    data: { token: token },
    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
    success: function(response) {
      if (response.success) {
        console.log('Token updated successfully');
      } else {
        console.log('Failed to update token: ' + response.message);
      }
    },
    error: function(xhr, status, error) {
      console.log('Error: ' + error);
    }
  });
}

window.show_toast = function(type, msg){
  toastr[type](msg, "", {
    "closeButton": true,
    "positionClass": "toast-top-center mt-2",
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "progressBar": true,
    "enableHtml": true,
    "escapeHtml": false,
    "hideMethod": "fadeOut" });
}

window.select_weight_unit = function(i, weight_unit_data){
  const options = ['kg', 'lb', 'oz', 'g'];
  const selectedOption = options.find(option => option === weight_unit_data) || 'kg';

  return `<select class='form-control is-valid select optional' id='variant_weight_unit_${i}' name='product_options_weight_unit[variant_weight_unit_${i}]' data-validate='true'>
      ${options.map(option => `<option value='${option}' ${option === selectedOption ? 'selected' : ''}>${option}</option>`).join('')}
  </select>`;
}


window.select_size_unit = function(i, size_unit_data){
  const options = ['cm', 'mm', 'm', 'in'];
  const selectedOption = options.find(option => option === size_unit_data) || 'cm';

  return `<select class='form-control is-valid select optional' id='variant_size_unit_${i}' name='product_options_size_unit[variant_size_unit_${i}]' data-validate='true'>
      ${options.map(option => `<option value='${option}' ${option === selectedOption ? 'selected' : ''}>${option}</option>`).join('')}
  </select>`;
}


window.block_ui = function(ui){
  $(ui).block({ message: block_message,
    css: { 
        border: 'none', 
        padding: '15px', 
        backgroundColor: '#000', 
        '-webkit-border-radius': '10px', 
        '-moz-border-radius': '10px', 
        opacity: .5, 
        color: '#fff' 
    },
    overlayCSS: { backgroundColor: '#fff' }
  });
}

window.open_link = function(link){
  location.href = link
}

var originalConsoleLog = console.log;
var currentHostName = window.location.hostname;
console.log = function(message) {
  if(currentHostName == "localhost"){
    originalConsoleLog("Development log: " + message);
  }
};


let block_message = '<div class="spinner-border" role="status"><span class="sr-only">Please wait ...</span></div>';

window.open_modal = function(link, url, gon_data = null, form_data = null){
  if(gon_data != null){
    gon = gon_data
  }

  var inputValues = {};
  if(form_data != null){
    var inputs = $("#"+form_data+" input");
    inputs.each(function() {
      var name = $(this).attr("id");
      var value = $(this).val();
      if ($(this).attr("type") === "checkbox") {
        value = $(this).is(":checked");
      }
      if(name != undefined){
        inputValues[name] = value;
      }
    });
  }

  var e = window.event;
  if (e) {
    e.preventDefault();
  }

  if($(link).closest('.card').length > 0){
    $(link).closest('.card').block({ message: block_message,
      css: { 
          border: 'none', 
          padding: '15px', 
          backgroundColor: '#000', 
          '-webkit-border-radius': '10px', 
          '-moz-border-radius': '10px', 
          opacity: .5, 
          color: '#fff' 
      },
      overlayCSS: { backgroundColor: '#fff' }
   });
  }else if($(link).closest('.tab-pane').length > 0){
    $(link).closest('.tab-pane').block({ message: block_message,
      css: { 
          border: 'none', 
          padding: '15px', 
          backgroundColor: '#000', 
          '-webkit-border-radius': '10px', 
          '-moz-border-radius': '10px', 
          opacity: .5, 
          color: '#fff' 
      },
      overlayCSS: { backgroundColor: '#fff' }
   });
  }

  $(link).addClass("disabled");
  $(link).attr("disabled","disabled");
  let old_html = $(link).html();
  $(link).html('<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>');
  $.ajax({
    type: "GET",
    url: url,
    data: {form_data: inputValues},
    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
    dataType: "html",
    success: function(result){
      document.getElementById("remote_modal").innerHTML = result;
      $(link).html(old_html);
      $(link).removeClass("disabled");
      $(link).removeAttr("disabled");
      if($(link).closest('.card').length > 0){
        $(link).closest('.card').unblock();
      }else if($(link).closest('.tab-pane').length > 0){
        $(link).closest('.tab-pane').unblock();
      }else if($(link).closest('.order-summary-wrapper').length > 0){
        
    }
    },
    statusCode: {
      401: function (xhr, error, thrown) {
        window.location ="/404"
        return false
      }
    },
    error: function ( data ) {

    }
  });
}


document.addEventListener("turbolinks:before-visit", function(e) {
  e.preventDefault()
  window.location = e.data.url
})

document.currentScript.setAttribute("data-turbolinks-eval", "true");



$( document ).ready(function() {

  $('div.form-group:has(#vendor_pricing_category_excluded_shops)').hide();

  $('#vendor_pricing_category_is_default').on('click', function() {
    if ($(this).is(':checked')) {
      // Show the excluded shops field when checkbox is checked
      $('div.form-group:has(#vendor_pricing_category_excluded_shops)').show();
    } else {
      // Hide and clear the excluded shops field when checkbox is unchecked
      $('div.form-group:has(#vendor_pricing_category_excluded_shops)').hide();
      $('#vendor_pricing_category_excluded_shops').val('');
    }
  });

  if (typeof  window.downloadAPP === "function") {
    window.downloadAPP();
  }

  if (typeof  window.fetch_create_options === "function") {
    if($('#product_groups_id').length > 0){
        window.fetch_create_options($('#product_groups_id').val().split(" "));
    }
  }

  if (typeof  init_js === "function") {
    // safe to use the function
    init_js();
  }

  feather.replace();


  initializeMobileInputs();

  while (dataTables.length !== 0) {
    dataTables.pop().destroy();
  }
  feather.replace();


  if (dataTables.length === 0 && $('.dataTable').length !== 0 || $('.dataTable-show').length !== 0) {
    datatable_init();
  }


  window.show_modal = function(){

  }

  if ($('.is_trial_product').length !== 0) {
    $(".is_trial_product").change(function () {
      if ($(this).prop('checked')) {
        $('#product_trial_product_shops').prop("disabled",false).trigger("chosen:updated");
        $('#product_trial_product_shops').removeClass("disabled");

        $('#product_product_trial_price').prop("disabled",false);
        $('#product_product_trial_price').removeClass("disabled");

        $('#product_product_trial_quantity').prop("disabled",false);
        $('#product_product_trial_quantity').removeClass("disabled");

        $('.allow-trial-product-request-fields').show();
      } else {
        $('#product_trial_product_shops').prop("disabled",true).trigger("chosen:updated");
        $('#product_trial_product_shops').addClass("disabled");

        $('#product_product_trial_price').prop("disabled",true)
        $('#product_product_trial_price').addClass("disabled");

        $('#product_product_trial_quantity').prop("disabled",true)
        $('#product_product_trial_quantity').addClass("disabled");

        $('.allow-trial-product-request-fields').hide();
      }
    });
  }


  
  if ($('#product_restriction_vendor_category_product_category').length !== 0){
    $('#product_restriction_vendor_category_product_category').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }


  if ($('#product_restriction_product_id').length !== 0){
    $('#product_restriction_product_id').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }

  if ($('#product_restriction_shop_ids').length !== 0){
    $('#product_restriction_shop_ids').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }

  


  


  if ($('#payment_method_credit_limit_per_shop_shop_id').length !== 0){
    $('#payment_method_credit_limit_per_shop_shop_id').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }


  if ($('#product_trial_product_shops').length !== 0){
    $('#product_trial_product_shops').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }


  if ($('#q_approval_status_in').length !== 0){
    $('#q_approval_status_in').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }

  if ($('#product_option_groups').length !== 0){
    $('#product_option_groups').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }

   if ($('#currency-select').length !== 0){    
    $('#currency-select').chosen({
      allow_single_deselect: true,
      width: '100%'
    }).on('change', function(event) {
      window.currencies_changes();
    });
   }

   if ($('#delivery_agency_vendors_options').length !== 0){
    $('#delivery_agency_vendors_options').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }

  if ($('#shop_pricing_category_pricing_category_shops').length !== 0){
    $('#shop_pricing_category_pricing_category_shops').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }

  if ($('#vendor_pricing_category_excluded_shops').length !== 0){
    $('#vendor_pricing_category_excluded_shops').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }


  if ($('.excluded_shops_field').length !== 0){
    $('.excluded_shops_field').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }

  if ($('#q_shop_id_in').length !== 0){
    $('#q_shop_id_in').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }

  if ($('#q_vendor_id_in').length !== 0){
    $('#q_vendor_id_in').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }

  if ($('#q_shop_order_shipping_pricing_table_shop_order_shop_id_in').length !== 0){
    $('#q_shop_order_shipping_pricing_table_shop_order_shop_id_in').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }

  if ($('#q_vendor_id_in').length !== 0){
    $('#q_vendor_id_in').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }

  if ($('#q_shop_order_shipping_pricing_table_shop_order_shop_order_shipping_delivery_orders_delivery_status_in').length !== 0){
    $('#q_shop_order_shipping_pricing_table_shop_order_shop_order_shipping_delivery_orders_delivery_status_in').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }

  if ($('#q_shop_order_item_shop_order_shop_id_in').length !== 0){
    $('#q_shop_order_item_shop_order_shop_id_in').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }

  if ($('#q_shop_order_shop_id_in').length !== 0){
    $('#q_shop_order_shop_id_in').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }

  if ($('#q_shop_order_items_shop_payment_method_id_in').length !== 0){
    $('#q_shop_order_items_shop_payment_method_id_in').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }

  if ($('#q_shop_order_shipping_delivery_orders_delivery_status_or_shop_order_items_order_item_delivery_order_delivery_status_in').length !== 0){
    $('#q_shop_order_shipping_delivery_orders_delivery_status_or_shop_order_items_order_item_delivery_order_delivery_status_in').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }

  if ($('#q_product_publish_status_in').length !== 0){
    $('#q_product_publish_status_in').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }
  
  if ($('#invitation-shop-categories').length !== 0){
    $('#invitation-shop-categories').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }

  if ($('#vendor_custom_tax_vendor_categories').length !== 0){
    $('#vendor_custom_tax_vendor_categories').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }


  if ($('#vendor_custom_tax_products').length !== 0){
    $('#vendor_custom_tax_products').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }

  if ($('#vendor_custom_tax_product_id').length !== 0){
    $('#vendor_custom_tax_product_id').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }


  if ( typeof init_shop_map == 'function' && $('#googlemap').length !== 0 ) {
    init_shop_map();
  }


  if ($('#product_options').length !== 0){
    $('#product_options').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }

  if ($('#shop_pricing_category_options ').length !== 0){
    $('#shop_pricing_category_options').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }


  

  if ($('#product_category_id').length !== 0){
    $('#product_category_id').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }



  if ($('#product_product_shipped_countries').length !== 0){
    $('#product_product_shipped_countries').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }

  if ($('#shop_billing_details_address_country_code').length !== 0){
    $('#shop_billing_details_address_country_code').chosen({
      allow_single_deselect: true,
      width: '100%'
    });
  }


if ($('#shop_vendor_ids').length !== 0){
  $('#shop_vendor_ids').chosen({
    allow_single_deselect: true,
    width: '100%'
  });
}


if ($('#shop_main_field_ids').length !== 0){
  $('#shop_main_field_ids').chosen({
    allow_single_deselect: true,
    width: '100%'
  });


  $('#shop_main_field_ids').on('change', function(evt, params) {
    if($('#shop_main_field_ids').chosen().val() == ""){
      $('.chosen-choices').addClass("form-control is-invalid");
    }else{
      $('.chosen-choices').removeClass("form-control is-invalid");
    }
  });
}

if ($('#user_delivery_agency_id').length !== 0){
  $('#user_delivery_agency_id').chosen({
    allow_single_deselect: true,
    width: '100%'
  });
}

if ($('#user_vendor_id').length !== 0){
  $('#user_vendor_id').chosen({
    allow_single_deselect: true,
    width: '100%'
  });
}

  
if ($('#brand_category_ids').length !== 0){
  $('#brand_category_ids').chosen({
    allow_single_deselect: true,
    width: '100%'
  });
}

if ($('#main_field_main_field_countries').length !== 0){
  $('#main_field_main_field_countries').chosen({
    allow_single_deselect: true,
    width: '100%'
  });



  $('#main_field_main_field_countries').on('change', function(evt, params) {
    if($('#main_field_main_field_countries').chosen().val() == ""){
      $('.chosen-choices').addClass("form-control is-invalid");
    }else{
      $('.chosen-choices').removeClass("form-control is-invalid");
    }
  });
}


  if ($('#vendor_pricing_category_bonus_product_ids').length !== 0){
    $('#vendor_pricing_category_bonus_product_ids').chosen({
        allow_single_deselect: true,
        width: '100%'
    });
  }

  if ($('#vendor_pricing_category_excluded_shops').length !== 0){
    $('#vendor_pricing_category_excluded_shops').chosen({
        allow_single_deselect: true,
        width: '100%'
    });
  }

  if ($('#vendor_main_field_ids').length !== 0){
    $('#vendor_main_field_ids').chosen({
      allow_single_deselect: true,
      width: '100%'
    });


    $('#vendor_main_field_ids').on('change', function(evt, params) {
      if($('#vendor_main_field_ids').chosen().val() == ""){
        $('.chosen-choices').addClass("form-control is-invalid");
      }else{
        $('.chosen-choices').removeClass("form-control is-invalid");
      }
    });
  }

  if ($('#vendor_vatrena_payment_ids').length !== 0){
    $('#vendor_vatrena_payment_ids').chosen({
      allow_single_deselect: true,
      width: '100%'
    });


    $('#vendor_vatrena_payment_ids').on('change', function(evt, params) {
      if($('#vendor_vatrena_payment_ids').chosen().val() == ""){
        $('.chosen-choices').addClass("form-control is-invalid");
      }else{
        $('.chosen-choices').removeClass("form-control is-invalid");
      }
    });
  }


  if ($('#vendor_vatrena_shipping_ids').length !== 0){
    $('#vendor_vatrena_shipping_ids').chosen({
      allow_single_deselect: true,
      width: '100%'
    });


    $('#vendor_vatrena_shipping_ids').on('change', function(evt, params) {
      if($('#vendor_vatrena_shipping_ids').chosen().val() == ""){
        $('.chosen-choices').addClass("form-control is-invalid");
      }else{
        $('.chosen-choices').removeClass("form-control is-invalid");
      }
    });
  }


  if ($('#vendor_site_language_ids').length !== 0){
    $('#vendor_site_language_ids').chosen({
      allow_single_deselect: true,
      width: '100%'
    });


    $('#vendor_site_language_ids').on('change', function(evt, params) {
      if($('#vendor_site_language_ids').chosen().val() == ""){
        $('.chosen-choices').addClass("form-control is-invalid");
      }else{
        $('.chosen-choices').removeClass("form-control is-invalid");
      }
    });
  }

  if ($('#vendor_vatrena_subscription_ids').length !== 0){
    $('#vendor_vatrena_subscription_ids').chosen({
      allow_single_deselect: true,
      width: '100%'
    });


    $('#vendor_vatrena_subscription_ids').on('change', function(evt, params) {
      if($('#vendor_vatrena_subscription_ids').chosen().val() == ""){
        $('.chosen-choices').addClass("form-control is-invalid");
      }else{
        $('.chosen-choices').removeClass("form-control is-invalid");
      }
    });
  }

  if($(".bs-stepper").length !==0){
    var stepper = new Stepper($(".bs-stepper")[0]);
  }

});


$(document).on('shown.bs.modal', function () { $(ClientSideValidations.selectors.forms).validate(); });

$(document).on("next_form", function () {
  $(ClientSideValidations.selectors.forms).resetClientSideValidations();
   $(ClientSideValidations.selectors.forms).validate();
 });

$(document).on("clear_form_validations", function () { $(ClientSideValidations.selectors.forms).resetClientSideValidations(); });

document.addEventListener("turbolinks:before-cache", () => {
  while (dataTables.length !== 0) {
    dataTables.pop().destroy();
  }
})

global.toastr = require("toastr")

global.feather = require("feather-icons");

window.mobile_number_err = function(iti,input,errorMsg,errorMap){
  input.classList.add("is-invalid");
  var errorCode = iti.getValidationError();
  if(errorMsg!=null){
    if(errorCode == -99){
      errorMsg.innerHTML = errorMap[0];
    }else{
      errorMsg.innerHTML = errorMap[errorCode];
    }
    errorMsg.classList.add("invalid-feedback-show");
    errorMsg.classList.remove("hide");
  }
}

window.initMap = function (...args) {
  const event = document.createEvent("Event");
  event.initEvent("mapsLoaded", true, true);
  event.args = args;
  window.dispatchEvent(event);
}


Rails.confirm = function(message, element) {
  let $element = $(element)
  event.preventDefault();
  Swal.fire({
    title: swal_title_msg,
    text: message,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    cancelButtonText: swal_cancel_button_text,
    confirmButtonText: swal_confirm_button_text
    }).then((result) => {
    if (result.isConfirmed) {
      let old = Rails.confirm
      Rails.confirm = function() { return true }
      $element.get(0).click();
      Rails.confirm = old;
    }
  });
  return false;
}


window.ClientSideValidations.callbacks.element.fail = function($element, message, callback) {
  callback();
  if ($element.hasClass("domain-name")) {
    var msg = $element.parent().find('.invalid-feedback').html();
    $element.parent().parent().find('.invalid-feedback').remove();
    $element.parent().after("<div class='invalid-feedback ingroup'>"+msg+"</div>");
  }
}

window.ClientSideValidations.callbacks.element.pass = function($element, callback) {
  $element.removeClass('is-invalid');
  if ($element.hasClass("domain-name")) {
    $element.parent().parent().find('.invalid-feedback').remove();
  }
}


document.addEventListener("DOMContentLoaded", function() {
  const trackableDiv = document.getElementById("trackable-div");

  if (trackableDiv) {
    trackableDiv.addEventListener("click", function() {
      // Track the event using Ahoy
      ahoy.track("100", { div_id: "trackable-div" });
    });
  }
});

import "stylesheets/application"

try{
  Rails.start()
  Turbolinks.start()
  ActiveStorage.start()
}catch(err){

}
