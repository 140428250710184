import IntlTelInput from 'intl-tel-input'
window.IntlTelInput = IntlTelInput
function initializeMobileInputs() {
var inputs = document.querySelectorAll(".mobile-number-flag");
inputs.forEach(function(input) {
  if(input!= null){
      var errorMsg = document.querySelector("#error-msg");
      var iti = IntlTelInput(input, {
      initialCountry: "auto",
      separateDialCode: true,
      preferredCountries: ["jo","ae"],
      utilsScript: "/js/utils.js",
      hiddenInput: "full_mobile_phone",
      customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
        return "e.g. " + selectedCountryPlaceholder;
      },
      geoIpLookup: function(success, failure) {
        $.get("https://ipinfo.io", function() {}, "jsonp").always(function(resp) {
          var countryCode = (resp && resp.country) ? resp.country : "us";
          success(countryCode);
        });
      },
    });


    // here, the index maps to the error code returned from getValidationError - see readme
    var errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];

    var reset = function() {
      input.classList.remove("is-invalid");
      if(errorMsg != null){
        errorMsg.innerHTML = "";
        errorMsg.classList.add("hide");
        errorMsg.classList.remove("invalid-feedback-show");
      }
    };

    // on blur: validate
    input.addEventListener('blur', function() {
      reset();
        if (iti.isValidNumber()) {

        } else {
          mobile_number_err(iti,input,errorMsg,errorMap);
        }
    });

    // on keyup / change flag: reset
    input.addEventListener('change', reset);
    input.addEventListener('keyup', reset);    
}
});

var telInputs = document.querySelectorAll(".telephone-number-flag");
telInputs.forEach(function(telInput) {
  if(telInput!= null){
      var telErrorMsg = document.querySelector("#error-msg-tel");
      var iti = IntlTelInput(telInput, {
      initialCountry: "auto",
      separateDialCode: true,
      preferredCountries: ["jo","ae"],
      utilsScript: "/js/utils.js",
      hiddenInput: "full_telephone_number",
      customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
        return "e.g. " + selectedCountryPlaceholder;
      },
      geoIpLookup: function(success, failure) {
        $.get("https://ipinfo.io", function() {}, "jsonp").always(function(resp) {
          var countryCode = (resp && resp.country) ? resp.country : "us";
          success(countryCode);
        });
      },
    });


    // here, the index maps to the error code returned from getValidationError - see readme
    var errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];

    var reset = function() {
      telInput.classList.remove("is-invalid");
      telErrorMsg.innerHTML = "";
      telErrorMsg.classList.add("hide");
      telErrorMsg.classList.remove("invalid-feedback-show");
    };

    // on blur: validate
    telInput.addEventListener('blur', function() {
      reset();
        if (iti.isValidNumber()) {

        } else {
          mobile_number_err(iti,telInput,telErrorMsg,errorMap);
        }
    });
    // on keyup / change flag: reset
    telInput.addEventListener('change', reset);
    telInput.addEventListener('keyup', reset);    
  }
});
}
export { initializeMobileInputs };