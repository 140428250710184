// app/javascript/controllers/checkbox_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox", "shipping_type", "vendor_id"]

  connect(){
    
    const checkbox = event.currentTarget
    const output = this.outputTarget

    var currency = "";
    var total_shipping = 0.0;
    var vendor_sum = {};

    $(".shipping-method-checkbox_"+this.vendor_idTarget.value+":checked").each(function(){
        var vendor_id = $(this).next().next().next().val();
        var current_selected_shipping_method = $(".shipping-method-checkbox_"+vendor_id+":checked").next().next().val();
        
        if(current_selected_shipping_method == "vatrenas_shipping"){
          $(".vatenas-shipping-details-"+vendor_id).css("display", "block");
          $(".vendor-local_pickup-details-"+vendor_id).css("display", "none");
        }else if (current_selected_shipping_method == "local_pickup"){
          $(".vatenas-shipping-details-"+vendor_id).css("display", "none");
          $(".vendor-local_pickup-details-"+vendor_id).css("display", "block");
        }else if(current_selected_shipping_method == "free_shipping"){
          $(".vatenas-shipping-details-"+vendor_id).css("display", "none");
          $(".vendor-local_pickup-details-"+vendor_id).css("display", "none");
        }
    });

    $(".shipping-method-time-table-radio:checked").each(function(){
      total_shipping = total_shipping + parseFloat($("#total_"+$(this).attr('id')).val());
      currency = $("#"+$(this).attr('id')+"-currency").val();
      var vendor_id = $(this).attr('id').split("_")[1];
      if(vendor_sum[vendor_id] == null){
        vendor_sum[vendor_id] = 0;
      }
      vendor_sum[vendor_id] = parseFloat(total_shipping);
      var total_vendor_sum = parseFloat(vendor_sum[vendor_id])+parseFloat($(".total-vendor_bottom_"+vendor_id).val())
      $(".total_price_vendor_bottom_"+vendor_id).html(total_vendor_sum.toFixed(2)+" "+currency)
    });

    $('.product-shipping-total').html(total_shipping.toFixed(2)+" "+currency);
    var sum_total = $(".sum-total").val();
    $(".total_price_right_bottom").text((parseFloat(sum_total)+parseFloat(total_shipping)).toFixed(2)+" "+currency);

  }

  update(event) {
    
    const checkbox = event.currentTarget
    const output = this.outputTarget

    var currency = "";
    var total_shipping = 0.0;
    var vendor_sum = {};

    if(this.shipping_typeTarget.value == "vatrenas_shipping"){
        vendor_sum = {};
        total_shipping = 0.0;
        $(".vatenas-shipping-details-"+this.vendor_idTarget.value).css("display", "block");
        $(".vendor-local_pickup-details-"+this.vendor_idTarget.value).css("display", "none");
        $(".shipping-method-time-table-radio:checked").each(function(){
          var vendor_id = $(this).attr('id').split("_")[1];
          currency = $("#"+$(this).attr('id')+"-currency").val();
          if ($(".vatenas-shipping-details-"+vendor_id).css('display') != 'none'){
            total_shipping = total_shipping + parseFloat($("#total_"+$(this).attr('id')).val());
            if(vendor_sum[vendor_id] == null){
              vendor_sum[vendor_id] = 0;
            }
            vendor_sum[vendor_id] = parseFloat(total_shipping);
            var total_vendor_sum = parseFloat(vendor_sum[vendor_id])+parseFloat($(".total-vendor_bottom_"+vendor_id).val())
            $(".total_price_vendor_bottom_"+vendor_id).html(total_vendor_sum.toFixed(2)+" "+currency)
          }else{
            $(".total_price_vendor_bottom_"+vendor_id).html(parseFloat($(".total-vendor_bottom_"+vendor_id).val()).toFixed(2)+" "+currency)
          }
        });
        

        $('.product-shipping-total').html(total_shipping.toFixed(2)+" "+currency);
        var sum_total = $(".sum-total").val();
        $(".total_price_right_bottom").text((parseFloat(sum_total)+parseFloat(total_shipping)).toFixed(2)+" "+currency);

    }else if(this.shipping_typeTarget.value == "local_pickup"){
      $(".vendor-local_pickup-details-" + this.vendor_idTarget.value).css("display", "block");
      $(".vatenas-shipping-details-" + this.vendor_idTarget.value).css("display", "none");
      $(".local-pickup-method-time-table-radio:not(:checked)").each(function(){
        const addressId = this.parentElement.dataset.addressId;
        console.log(addressId);
        console.log(this.parentElement);
        console.log("this is:" + addressId)
        document.querySelectorAll(`#pick_up_day${addressId} input[type="radio"][name^="shipping_days_"]`).forEach(dayRadio => {
          dayRadio.disabled = true;
          dayRadio.checked = false;
        });
      });
      this.clear_data();
      
      const addressRadios = document.querySelectorAll('input[type="radio"][name^="vendor_address_"]');
      
      addressRadios.forEach(radio => {
        radio.addEventListener('change', function () {
          const addressId = this.parentElement.dataset.addressId;
          
          document.querySelectorAll(`#pick_up_day${addressId} input[type="radio"][name^="shipping_days_"]`).forEach(dayRadio => {
            dayRadio.disabled = false;
          });
          
          addressRadios.forEach(r => {
            if (r !== this) {
              const otherAddressId = r.parentElement.dataset.addressId;
              document.querySelectorAll(`#pick_up_day${otherAddressId} input[type="radio"][name^="shipping_days_"]`).forEach(dayRadio => {
                dayRadio.disabled = true;
              });
            }
          });
          const firstDayRadio = document.querySelector(`#pick_up_day${addressId} input[type="radio"][name^="shipping_days_"]`);
          if (firstDayRadio) {
            firstDayRadio.checked = true;
          }
        });
      });
    }else if(this.shipping_typeTarget.value == "free_shipping"){
      $(".vendor-local_pickup-details-"+this.vendor_idTarget.value).css("display", "none");
      $(".vatenas-shipping-details-"+this.vendor_idTarget.value).css("display", "none");
      this.clear_data();
    }
  }

  clear_data(){
    var currency = "";
    var total_shipping = 0.0;
    var vendor_sum = {};
    total_shipping = 0.0;
    $(".vatenas-shipping-details-"+this.vendor_idTarget.value).css("display", "none");
      
    $(".shipping-method-time-table-radio:checked").each(function(){
      var vendor_id = $(this).attr('id').split("_")[1];
      currency = $("#"+$(this).attr('id')+"-currency").val();
      if ($(".vatenas-shipping-details-"+vendor_id).css('display') != 'none'){
        total_shipping = total_shipping + parseFloat($("#total_"+$(this).attr('id')).val());
        if(vendor_sum[vendor_id] == null){
          vendor_sum[vendor_id] = 0;
        }
        vendor_sum[vendor_id] = parseFloat(total_shipping);
        var total_vendor_sum = parseFloat(vendor_sum[vendor_id])+parseFloat($(".total-vendor_bottom_"+vendor_id).val())
        $(".total_price_vendor_bottom_"+vendor_id).html(total_vendor_sum.toFixed(2)+" "+currency);
      }else{
        $(".total_price_vendor_bottom_"+vendor_id).html(parseFloat($(".total-vendor_bottom_"+vendor_id).val()).toFixed(2)+" "+currency)
      }
    });

    $('.product-shipping-total').html(total_shipping.toFixed(2)+" "+currency);
    var sum_total = $(".sum-total").val();
    $(".total_price_right_bottom").text((parseFloat(sum_total)+parseFloat(total_shipping)).toFixed(2)+" "+currency);
  }
}
