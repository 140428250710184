import { Controller } from "stimulus"

import Select2 from "select2"

export default class extends Controller {
  connect() {
    $('.content-search').select2({
      tags: true,
      multiple: true
    });

    $(".content-search").on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });
  }
}
