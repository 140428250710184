window.variationCheck = function () {
    var self = this,
    isAllSelected = true;

    // check all select variations are selected
    $selects.each(function () {
        return this.value || (isAllSelected = false);
    });

    // check all item variations are selected
    var selected_price = 0;
    $items.each(function () {
        var $this = $(this);
        if ($this.children('a:not(.size-guide)').length) {
            if($this.children('.active').length>0){
              selected_price = $this.children('.active')[0].dataset["price"]
            }
            return $this.children('.active').length || (isAllSelected = false);
        }
    });

    isAllSelected ?
        variationMatch(selected_price, $('.product-variations').children('.active').length ) :
        variationClean();
}

window.variationMatch = function (selected_price, variants_length) {
    var self = this;
    if(variants_length>0){
      get_new_price(gon['variant_price_url']);
    }
}

window.variationClean = function (reset) {
    reset && $selects.val('');
    reset && $items.children('.active').removeClass('active');
    $btnCart.addClass('disabled');
    $('.btn-card-modal').prop('disabled', true);

    $('.request-trial-btn').css('pointer-events', 'none');
    $('.request-trial-btn').closest('div').addClass('disabled');

    $(".new-price").html(gon['variant_price']);
    $("#options").val('');
}

window.init_quantity = function(){
    $('.quantity-minus')
            .on('mousedown', function (e) {
                e.preventDefault();
                startDecrease();
            })
            .on('touchstart', function (e) {
                if (e.cancelable) {
                    e.preventDefault();
                }
                startDecrease();
            })

    $('.quantity-plus')
                .on('mousedown', function (e) {
                    e.preventDefault();
                    startIncrease();
                })
                .on('touchstart', function (e) {
                    if (e.cancelable) {
                        e.preventDefault();
                    }
                    startIncrease();
                })

    $('.quantity').on('input', function() {
        $("#product_quantity").val($('.quantity').val());
        // do something
    });

    $('.quantity').on('focusout', function() {
        if($('.quantity').val() < parseInt(gon["min_quantity"])){
            $('.quantity').val(gon["min_quantity"])
        }
        $("#product_quantity").val($('.quantity').val());
    });
}

window.init_variations = function(){

    $selects = $('.product-variations select');
    $items = $('.product-variations');
    $priceWrap = $('.product-variation-price');
    $clean = $('.product-variation-clean'),
    $btnCart = $('.btn-card-modal');

    // check
    variationCheck();
    $selects.on('change', function (e) {
        variationCheck();
    });
    $items.children('a').on('click', function (e) {

        $(this).toggleClass('active').siblings().removeClass('active');
        e.preventDefault();
        variationCheck();
    });

    // clean
    $clean.on('click', function (e) {
        e.preventDefault();
        variationClean(true);
    });
}

    window.startIncrease = function (e) {
        e && e.preventDefault();

        $('.quantity').val(parseInt($('.quantity').val())+1);
    }

    window.startDecrease = function (e) {
        e && e.preventDefault();

        if(parseInt($('.quantity').val()) > parseInt(gon["min_quantity"])){
            $('.quantity').val(parseInt($('.quantity').val())-1)
        }
    }
