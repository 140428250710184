import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["category", "products"]
    static values ={
        url: String,
        vendor: String,
        selected: String,
        all: String,
        categoryid: String,
        productid: String,
        customtax: Boolean
    }
    connect() {
        if(this.selectValue().length > 0){
            this.fetch()
        }
        if ($('#'+this.categoryidValue).length !== 0){
            var _self = this;
            $('#'+this.categoryidValue).chosen({
                allow_single_deselect: true,
                width: '100%'
            }).change(function(){
                _self.change();
            });
        }
    }

    change(){
        this.fetch()
    }

    selectName(){
        return this.categoryTarget.selectedOptions[0].text
    }

    selectValue(){
        var arr_ids = []
        for(var i=0; i<this.categoryTarget.selectedOptions.length;i++){
            arr_ids[i] =  this.categoryTarget.selectedOptions[i].value
        }
        return arr_ids
    }

    fetch() {
        // fetch(`${this.urlValue}?${this.params()}`)
        fetch(`${this.urlValue}?${this.params()}`, {
            headers: {
                Accept: "text/vnd.turbo-stream.html"
            }
        })
            .then(r => r.text())
            .then(html => this.insertData(this,html))
    }

    insertData(obj,html){
        obj.productsTarget.innerHTML = "";
        obj.productsTarget.insertAdjacentHTML('beforeend',html);
        select_product();
        $("#"+this.productidValue).trigger("chosen:updated");
        $("#"+this.productidValue).trigger("liszt:updated");
        const selectedProduct = $("#international_shipping_selected_product").val();
        if (selectedProduct) {
            $("#"+this.productidValue).val(selectedProduct.split(','));
            $("#"+this.productidValue).trigger("chosen:updated");
        }
        if ($('#vendor_custom_tax_vendor_category_product_category').length > 0) {
            var is_all = $('#vendor_custom_tax_vendor_category_product_category').val().includes('-1');
            $('#vendor_custom_tax_product_id').prop("disabled", is_all).trigger("chosen:updated");
            $('#vendor_custom_tax_product_id').toggleClass("disabled", is_all);
        }
    }

    params(){
        let params = new URLSearchParams()
        params.append('id', this.selectValue())
        params.append('select', this.productsTarget.id)
        params.append('show_all',this.allValue)
        params.append('vendor_id',this.vendorValue)
        if(this.customtaxValue == undefined || this.customtaxValue == false){
            params.append('custom_tax',false)
        }else{
            params.append('custom_tax',this.customtaxValue)
            params.append('country',$("#vendor_custom_tax_country_code").val())
            params.append('city',$("#vendor_custom_tax_city_code").val())
        }
        return params
    }
}
